import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "@layout/layout"
import { pl, en } from "@content/pages/sasa-box.yaml"
import HeaderSubpage from "@layout/header-subpage"
import SEO from "@layout/seo"

export default ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <Layout isEn={isEn}>
      <SEO
        isEn={isEn}
        title={translation.metaTitle}
        description={translation.metaDescription}
      />
      <HeaderSubpage isEn={isEn}/>
      <section className="section wrapper">
        <div
          className="padding sasaboxbg text-center zb-lazy  b-loaded"
          style={{
            backgroundImage: `url(${withPrefix("images/sasa-box/hero.png")})`,
          }}
        >
          <div className="row collapse">
            <div className="large-12 columns text-center">
              <h1 style={{ color: "#ffffff" }}>{translation.title}</h1>
            </div>
          </div>
        </div>
      </section>
      <article className="news-text padding">
        <div className="row">
          <div className="large-8 large-centered medium-12 small-12 columns">
            <p>{translation.description}</p>
            <div
              className="row"
              data-equalizer="photo1"
              data-resize="photo1"
              data-mutate="photo1"
              data-events="mutate"
            >
              <div className="large-8 medium-8 small-12 columns">
                <div
                  className="display-table small-block"
                  data-equalizer-watch="photo1"
                  style={{ height: "441px" }}
                >
                  <div className="display-table-cell">
                    <p>
                      <a href="https://sasafoundation.com/sasabox/">
                        <img
                          src={withPrefix("images/sasa-box/krajmas.jpg")}
                          alt=""
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="large-4 medium-4 small-12 columns">
                <div
                  className="display-table small-block"
                  data-equalizer-watch="photo1"
                >
                  <div className="display-table-cell">
                    <p className="red flex-cont">
                      <em>{translation.label_top}</em>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="photo2_top">
              <div
                className="row"
                data-equalizer="photo2"
                data-resize="photo2"
                data-mutate="photo2"
                data-events="mutate"
              >
                <div className="large-8 medium-8 small-12 small-push-12 medium-push-4 large-push-4 columns">
                  <div
                    className="display-table small-block"
                    data-equalizer-watch="photo2"
                  >
                    <div className="display-table-cell">
                      <p>
                        <a href={"#"}>
                          <img
                            src={withPrefix("images/sasa-box/otoskop.jpg")}
                            alt=""
                          />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="large-4 medium-4 small-12 small-pull-12 medium-pull-8 large-pull-8 columns text-left medium-text-right">
                  <div
                    className="display-table small-block"
                    data-equalizer-watch="photo2"
                  >
                    <div className="display-table-cell">
                      <p className="red flex-cont">
                        <em>{translation.label_bottom}</em>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: translation.content }} />
          </div>
        </div>
      </article>
    </Layout>
  )
}
